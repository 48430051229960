<template>
  <layout name="HomeLayout">
    <div class="Home">
      <section class="uk-section uk-section-small">
        <div class="uk-container">
          <div v-if="introStage">
            <!-- <p class="uk-text-center">UBT 시험시행기관 : ㈜고려의학</p> -->
            <h1 class="uk-text-center">1급 응급구조사 모의시험</h1>
            <h5 class="uk-text-center">
              sdf sdfsdf sdf sdfsdfsefsdf.sdf sdf sdfsdf sdfsdf sdf sdfsdf sdf sdfsdfsefsdf.sdf sdf sdfsdf sdfsdfsdf sdfsdf sdf sdfsdfsefsdf.sdf sdf sdfsdf sdfsdfsdf sdfsdf sdf sdfsdfsefsdf.sdf
              sdf sdfsdf sdfsdfsdf sdfsdf sdf sdfsdfsefsdf.sdf sdf sdfsdf sdfsdfsdf sdfsdf sdf sdfsdfsefsdf.sdf sdf sdfsdf sdfsdf
            </h5>

            <p class="uk-text-center uk-margin-big">
              <button
                @click="startQuiz"
                class="uk-button uk-button-primary uk-width-1-2"
              >다음</button>
            </p>
          </div>

          <div v-if="questionStage">
            <p class="uk-text-center">
              <question
                :question="questions[currentQuestion]"
                v-on:answer="handleAnswer"
                v-on:answerprev="handleAnswerPrev"
                :question-number="currentQuestion+1"
              ></question>
            </p>
          </div>

          <div
            v-if="resultsStage"
          >You got {{correct}} right out of {{questions.length}} questions. Your percentage is {{perc}}%.</div>
        </div>
      </section>
    </div>
  </layout>
</template>

<script scoped>
import Layout from '../../../layouts/Layout'
import question from './QuizItem.vue'
export default {
  components: {
    Layout,
    question
  },
  data () {
    return {
      introStage: false,
      questionStage: false,
      resultsStage: false,
      title: '',
      questions: [],
      currentQuestion: 0,
      answers: [],
      correct: 0,
      perc: null,
      url: 'https://api.myjson.com/bins/ahn1p'
    }
  },
  created () {
    fetch(this.url)
      .then(res => res.json())
      .then(res => {
        this.title = res.title
        this.questions = res.questions
        this.introStage = true
      })
  },
  methods: {
    startQuiz () {
      this.introStage = false
      this.questionStage = true
    },
    handleAnswer (e) {
      this.answers[this.currentQuestion] = e.answer
      if (this.currentQuestion + 1 === this.questions.length) {
        this.handleResults()
        this.questionStage = false
        this.resultsStage = true
      } else {
        this.currentQuestion++
      }
    },
    handleAnswerPrev () {
      if (this.currentQuestion > 0) {
        this.currentQuestion--
      }
    },
    handleResults () {
      this.questions.forEach((a, index) => {
        if (this.answers[index] === a.answer) this.correct++
      })
      this.perc = ((this.correct / this.questions.length) * 100).toFixed(2)

    }
  }
}
</script>

<style scoped>
</style>
