<template>
  <div>
    <strong>Question {{ questionNumber }}:</strong>
    <br>
    <strong>{{ question.text }}</strong>

    <div v-if="question.type === 'tf'">
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <input
          class="uk-radio"
          type="radio"
          name="currentQuestion"
          id="trueAnswer"
          v-model="answer"
          value="t"
        >
        <label for="trueAnswer">True</label>
        <br>
        <input
          class="uk-radio"
          type="radio"
          name="currentQuestion"
          id="falseAnswer"
          v-model="answer"
          value="f"
        >
        <label for="falseAnswer">False</label>
        <br>
      </div>
    </div>

    <div v-if="question.type === 'mc'">
      <div v-for="(mcanswer,index) in question.answers" :key="question.answers[index].id">
        <input
          type="radio"
          :id="'answer'+index"
          name="currentQuestion"
          v-model="answer"
          :value="mcanswer"
        >
        <label :for="'answer'+index">{{mcanswer}}</label>
        <br>
      </div>
    </div>
    <button
      @click="prevAnswer"
      v-if="questionNumber > 1"
      class="uk-button uk-button-default uk-button-small"
    >Prev</button>
    <button type="button" @click="submitAnswer" class="uk-button uk-button-primary uk-button-small">Next</button>
  </div>
</template>

<script>
export default {
  props: ['question', 'question-number'],
  data () {
    return {
      answer: ''
    }
  },
  methods: {
    submitAnswer: function () {
      this.$emit('answer', { answer: this.answer })
      this.answer = null
    },
    prevAnswer: function () {
      this.$emit('answerprev', { answer: this.answer })
      this.answer = null
    }
  }
}
</script>

<style scoped>
</style>
